import { useNavigate, useParams } from "react-router-dom"
import ViewService from "../../services/view.service";
import { TranslatorService } from "../../services/translator.service";
import { useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, FloatingLabel, Form, Image, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import Multimedia from "./Multimedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBackward, faMinus, faPlus, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import UploadService from "../../services/upload.service";
import toast from "react-hot-toast";
import Select from "react-select"
import ViewMultimedia from "./ViewMultimedia";
import Skeleton from "react-loading-skeleton";
import QuestionExplanation, { ViewQuestionExplanation } from "./QuestionExplanation";
import ExplanationComponent from "../../components/Explanation/ExplanationCompoent";

export default function TranslatorById() {

    const { questionId } = useParams();
    const navigate = useNavigate()

    const [questions, setQuestions] = useState<any>();
    const [selectedTabs, setSelectedTabs] = useState<any>();
    const [allowedLanguage, setAllowedLanguage] = useState<any>();
    const [edit, setEdit] = useState<boolean>(false);
    const [multimediaData, setMultimediaData] = useState<any>();
    const [multimediaAttachmentData, setMultimediaAttachmentData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectLanguage, setSelectLanguage] = useState<any>();
    const [selectEditLanguage, setSelectEditLanguage] = useState<any>()
    const [questionText, setQuestionText] = useState<any>()
    const [pageLoading, setPageLoading] = useState<boolean>(false)
    const [viewLngQuestionExplanation, setViewLangQuestionExplanation] = useState<any>({});
    const [editLngQuestionExplanation, setEditLngQuestionExplanation] = useState<any>({});

    const [selectedOptionData, setSelectedOptionData] = useState<any>({
        optionsData: [],
    });


    const getQuestionByQuestionId = async () => {
        setPageLoading(true)
        await TranslatorService.getQuestionByQuestionId(questionId).then((res: any) => {
            setQuestions(res.data);
        })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setPageLoading(false)
            })
    }

    const getUserAllowedLanguage = async () => {
        await TranslatorService.getUserAllowedLanguage().then((res) => {
            if (res.status === 200) {
                setAllowedLanguage(res.data.map((lang: any) => {
                    return {
                        label: lang,
                        value: lang
                    }
                }));
            }
        })
    }

    const handleMultiMediaUpload = async (e: any) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);
        setLoading(true);
        await UploadService.uploadMultimedia(form_data).then((res) => {
            if (res.status === 200) {
                setMultimediaData({ ...multimediaData, [e.target.name]: res.data });
            }
        }).catch(e => {
            toast.error("Something went wrong when uploading Multimedia");
        })
        setLoading(false)
    }

    const handleQuestionAttachements = async (e: any) => {
        let form_data = new FormData;
        form_data.append('file', e.target.files[0]);
        await UploadService.uploadMultimedia(form_data).then((res) => {
            if (res.status === 200) {
                setMultimediaAttachmentData((arr: any) => [...arr, res.data]);
            }
        }).catch(e => {
            toast.error("Something went wrong when uploading Question Attachments");
        })
    }

    const addOption = () => {
        let currentOptionsData = selectedOptionData.optionsData || [];
        currentOptionsData.push("");
        setSelectedOptionData({ ...selectedOptionData, optionsData: currentOptionsData });
    }

    const deleteOption = () => {
        let currentOptionsData = selectedOptionData.optionsData || [];
        currentOptionsData.pop();
        setSelectedOptionData({ ...selectedOptionData, optionsData: currentOptionsData });
    }

    const handleUpdateOptions = (e: any, index: number) => {
        const updatedOptionsData = [...selectedOptionData.optionsData];
        updatedOptionsData[index] = { value: e.target.value };
        setSelectedOptionData({ optionsData: updatedOptionsData });
    }

    const allowed = questions?.questionData?.find((lang: any) => lang.language === selectLanguage);
    const editAllowed = questions?.questionData?.find((lang: any) => lang.language === selectEditLanguage);
    const trainslatedLanguages = allowedLanguage?.filter((lang: any) => questions?.questionData?.find((data: any) => data.language === lang.value));

    const renderOptionsData = () => {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <Form.Label className="xcn-text-12 text-secondary">Option Data</Form.Label>
                    </div>
                    <div>
                        {questions?.optionType != "boolean" ?
                            <>
                                <FontAwesomeIcon icon={faPlus} className='text-success me-2 xcn-link-pointer' size="sm" onClick={addOption} />
                                <FontAwesomeIcon icon={faMinus} className='text-danger xcn-link-pointer' size="sm" onClick={deleteOption} />
                            </> : " "}
                    </div>
                </div>
                <div className="mt-2">
                    {selectedOptionData && selectedOptionData.optionsData && selectedOptionData.optionsData.map((data: any, index: number) => {
                        return (
                            <FloatingLabel
                                controlId={"option" + index}
                                label={"Option " + (index + 1)}
                                className="mb-3"
                            >
                                <Form.Control type="text" value={data?.value} onChange={(e: any) => handleUpdateOptions(e, index)} disabled={questions?.optionType == "boolean" ? true : false} />
                            </FloatingLabel>
                        )
                    })}
                </div>
            </>
        )
    }

    const handleCreateAndUpdateQuestion = async (allowed: any, language: any) => {

        const payload = {
            image: multimediaData ? multimediaData?.image : allowed?.image,
            audio: multimediaData ? multimediaData?.audio : allowed?.audio,
            video: multimediaData ? multimediaData?.video : allowed?.video,
            attachments: multimediaAttachmentData ? multimediaAttachmentData : allowed?.attachments,
            options: selectedOptionData.optionsData ? selectedOptionData.optionsData : allowed?.options,
            questionText: questionText,
            explanation: {
                image: editLngQuestionExplanation?.image,
                audio: editLngQuestionExplanation?.audio,
                video: editLngQuestionExplanation?.video,
                text: editLngQuestionExplanation?.text
            }
        }

        toast.promise(
            TranslatorService.createAndUpdatedQuestion(questionId, language, payload).then((res) => {
                if (res.status === 200) {
                    getQuestionByQuestionId();
                    setEdit(false);
                    setMultimediaData("");
                    setMultimediaAttachmentData([]);
                }
            }),
            {
                loading: 'Uploading...',
                success: "Question Added/Edited Successfully",
                error: "Unable to upload this question.",
            }
        );
    }


    useEffect(() => {
        getQuestionByQuestionId();
    }, []);

    // useEffect(() => {
    //     setEditLngQuestionExplanation(questions?.explanations?.find((data: any) => data.langId === editAllowed?._id));
    // }, [selectEditLanguage]);

    // useEffect(() => {
    //     console.log(allowed, "allowed")
    //     setViewLangQuestionExplanation(questions?.explanations?.find((data: any) => data.langId === allowed?._id));
    // }, [selectLanguage]);


    useEffect(() => {
        getUserAllowedLanguage();
    }, [])

    console.log(questions, "translator................")

    if (pageLoading) {
        return (
            <>
                <Skeleton height={50} />
                <Row>
                    <Col md={6}>
                        <Skeleton height={500} count={2} />
                    </Col>
                    <Col md={6}>
                        <Skeleton height={500} count={2} />
                    </Col>
                </Row>
            </>
        )
    }


    return (
        <>
            <Card className="shadow-sm">
                <Card.Body className="d-flex justify-content-between align-items-center">

                    <div className="d-flex  align-items-center">
                        <FontAwesomeIcon icon={faArrowLeft} className="mb-2" onClick={() => navigate(-1)} />
                        <h5 className="ms-2">Subject: </h5>
                        <h6 className="ms-2 text-muted fw-bold">{questions?.subject}</h6>
                    </div>
                    <div className="d-flex  align-items-center">
                        <h5>Question Type: </h5>
                        <h6 className="ms-2 text-muted fw-bold">{questions?.questionType}</h6>
                    </div>
                    <div className="d-flex  align-items-center">
                        <h5>Option Type: </h5>
                        <h6 className="ms-2 text-muted fw-bold">{questions?.optionType}</h6>
                    </div>
                    <div className="d-flex  align-items-center">
                        <h5>Frequency: </h5>
                        <h6 className="ms-2 text-muted fw-bold">{questions?.frequencyUsed}</h6>
                    </div>
                    <div className="d-flex  align-items-center">
                        <h5>Difficulty: </h5>
                        <h6 className="ms-2 text-muted fw-bold">{questions?.dificulty}</h6>
                    </div>
                </Card.Body>
            </Card>
            <Row>
                <Col>
                    <Card className="mt-3 shadow-sm">
                        <Card.Body>
                            <div>
                                <div>
                                    <div className=" mt-3 d-flex justify-content-between align-items-center">
                                        <h5>View Question</h5>
                                        <div style={{ width: "15rem" }}>
                                            <Select options={trainslatedLanguages} placeholder="Select Language" onChange={(e: any) => setSelectLanguage(e.value)} value={trainslatedLanguages?.find((x: any) => x.value == selectLanguage)} />
                                        </div>
                                    </div>
                                    {selectLanguage ? <>
                                        <div>
                                            <ViewMultimedia
                                                allowed={allowed}
                                            />
                                            <ExplanationComponent questionData={questions} />
                                        </div>
                                    </> : <span className="fw-bold text-muted mt-2">Please Select Language to view question</span>}

                                </div>
                            </div>
                        </Card.Body>
                    </Card >
                </Col>
                <Col>
                    <Card className="mt-3 shadow-sm">
                        <Card.Body>
                            <div>
                                <div className=" mt-3 d-flex justify-content-between align-items-center">
                                    <h5>Question Translation</h5>
                                    <div className="d-flex align-items-center">
                                        <div style={{ width: "15rem" }}>
                                            <Select className="me-2" options={allowedLanguage} placeholder="Select Language" onChange={(e: any) => { setSelectEditLanguage(e.value); setEdit(false) }} value={allowedLanguage?.find((x: any) => x.value == selectEditLanguage)} />
                                        </div>
                                    </div>
                                </div>
                                {selectEditLanguage ? <>
                                    <div className="d-flex justify-content-end mt-2 align-items-center">
                                        {edit ?
                                            <>
                                                <Button variant="warning" onClick={() => handleCreateAndUpdateQuestion(editAllowed, selectEditLanguage)}>Save</Button>
                                                <Button variant="secondary" className="ms-2" onClick={() => setEdit(!edit)}>Cancel</Button>
                                            </> :
                                            <Button variant="secondary" onClick={() => setEdit(!edit)}>Edit</Button>
                                        }
                                        {loading && <Spinner animation="border" className="ms-2 text-center" />}
                                    </div>
                                    <div>
                                        <Multimedia
                                            allowed={editAllowed}
                                            handleMultiMediaUpload={handleMultiMediaUpload}
                                            handleQuestionAttachements={handleQuestionAttachements}
                                            setMultimediaAttachmentData={setMultimediaAttachmentData}
                                            multimediaAttachmentData={multimediaAttachmentData}
                                            setQuestionText={setQuestionText}
                                            questionText={questionText}
                                            isEdit={edit}
                                            selectedLanguage={selectLanguage}
                                            selectedEditLanguage={selectEditLanguage}
                                            reload={getQuestionByQuestionId}
                                            allowed_id={allowed && allowed._id}
                                        />
                                    </div>
                                    <Card className="reg-card box-shadow mt-3 mb-4">
                                        <Card.Body>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label className="xcn-text-12 text-secondary">Option Data</Form.Label><br />
                                                    <div>
                                                        {editAllowed?.options?.length > 0 ? editAllowed?.options?.map((option: any, index: number) => {
                                                            return (
                                                                <div className='fw-bold xcn-text-15'><span className="text-muted">Option {index + 1}. </span>{option.value}</div>
                                                            )
                                                        }) : <Alert variant="danger">
                                                            <FontAwesomeIcon icon={faTriangleExclamation} />
                                                            <span className="ms-3">No Options Data Available</span>
                                                        </Alert>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    {edit &&
                                        <Card className="reg-card box-shadow mt-3 mb-4">
                                            <Card.Body>
                                                <Row>
                                                    <Col md={4}>
                                                        {renderOptionsData()}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    }

                                    {
                                        edit ?
                                            <QuestionExplanation data={editLngQuestionExplanation} setData={setEditLngQuestionExplanation} /> :
                                            <ExplanationComponent questionData={questions} />
                                        // : <ViewQuestionExplanation data={editLngQuestionExplanation} />
                                    }

                                </>
                                    : <span className="fw-bold text-muted mt-2">Please Select Language to add/edit question translation</span>
                                }
                            </div>
                        </Card.Body>
                    </Card >
                </Col >
            </Row >
        </>
    )
}


// explanation: Joi.object({
//     image:"",
//     text: "",
//     video: "",
//     audio: "",
// }).optional(), 