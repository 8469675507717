import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Form, Image, Row } from 'react-bootstrap';
import QAService from '../../services/qa.service';
import AudioPlayer from 'react-h5-audio-player';
import ExplanationComponent from '../../components/Explanation/ExplanationCompoent';

interface IQuestionData {
    questionData: any
}

const QuestionsData = ({ questionData }: IQuestionData) => {

    const [encData, setEncData] = useState<any>()
    const [encVideo, setEncVideo] = useState<any>("");
    const [encAudio, setEncAudio] = useState<any>();


    const getEncMediaImage = async () => {
        await QAService.getEncMultimedia(questionData.image).then((res => {
            if (res.status === 200) {
                setEncData(res.data);
            }
        }))
    }

    const getEncMediaVideo = async () => {
        await QAService.getEncMultimedia(questionData && questionData.video).then((res => {
            if (res.status === 200) {
                setEncVideo(res.data.toString());
            }
        }))
    }

    const getEncMediaAudio = async () => {
        await QAService.getEncMultimedia(questionData && questionData.audio).then((res => {
            if (res.status === 200) {
                setEncAudio(res.data.toString());
            }
        }))
    }


    useEffect(() => {
        if (questionData && questionData.image) {
            getEncMediaImage();
        }
        if (questionData && questionData.video) {
            getEncMediaVideo();
        }
        if (questionData && questionData.audio) {
            getEncMediaAudio();
        }

    }, [questionData])


    useEffect(() => {
        if (questionData && questionData.wordText) {
            const element = document.getElementById("statement-text-question");
            if (element) {
                element.innerHTML = questionData.wordText
            }
        }
    }, [questionData?.wordText])


    return (
        <>
            {questionData && questionData?.wordText &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Label className="xcn-text-12 text-secondary">Question Text</Form.Label><br />
                                <div className='pb-3'>
                                    <div id="statement-text-question"></div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            {questionData && questionData.image &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Label className="xcn-text-12 text-secondary">Question Image</Form.Label><br />
                                <div className="mb-4">
                                    <Image src={encData && encData.split(";base64,")[1] ? encData : "data:image/jpeg;base64," + encData} className="w-100" />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Label className="xcn-text-12 text-secondary">Option Data</Form.Label><br />
                            <div>
                                {questionData && questionData.options && questionData.options.map((option: any, index: number) => {
                                    const correct_answer = questionData?.answers?.includes(option._id);
                                    return (
                                        <div className={`w-100 fw-bold xcn-text-15`}>
                                            <span className="text-muted">Option {index + 1}. {option.value} {correct_answer && <span className="ms-2 text-secondary" style={{ fontSize: 12 }}>(Correct Answer)</span>}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {questionData && questionData.video && encVideo &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Row>

                            <Col md={4}>
                                <Form.Label className="xcn-text-12 text-secondary">Question Video</Form.Label><br />
                                <video width="320" height="240" controls>
                                    <source type="video/mp4" src={encVideo} />
                                    <source type="video/ogg" src={encVideo} />
                                </video>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            {questionData && questionData.audio && encAudio &&
                <Card className="px-2 mt-3" style={{ backgroundColor: "#D0D5DD60", borderRadius: 8, outline: "none", border: "none" }}>
                    <Card.Body>
                        <Row>

                            <Col md={4}>
                                <Form.Label className="xcn-text-12 text-secondary">Question Audio</Form.Label><br />
                                <AudioPlayer
                                    autoPlay
                                    src={encAudio}
                                    onPlay={e => console.log("onPlay")}
                                    muted
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            {questionData && questionData.explanation && <ExplanationComponent questionData={questionData} />}
        </>
    )
}

export default QuestionsData